// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-danke-js": () => import("./../src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-rebberg-js": () => import("./../src/pages/rebberg.js" /* webpackChunkName: "component---src-pages-rebberg-js" */),
  "component---src-pages-schoepfli-js": () => import("./../src/pages/schoepfli.js" /* webpackChunkName: "component---src-pages-schoepfli-js" */),
  "component---src-pages-weine-js": () => import("./../src/pages/weine.js" /* webpackChunkName: "component---src-pages-weine-js" */)
}

